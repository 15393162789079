$red:#f3594c;
$dark-blue: #495161;
$dark-red: #B34237;
$green: #41b883;
$orange: #f4bf29;
$accent-color: $red;
$accent-secondary-color: $dark-blue;
$accent-tertiary-color: $orange;
$white: #ffffff;
$black: #000000;

$gray-000: $white;
$gray-100: #edeae5;
$gray-200: #d8d6cd;
$gray-400: #c8c9ba;
$gray-500: #aca79e;
$gray-550: #97938c;
$gray-600: #888894;
$gray-700: #414042;
$gray-800: #3b3d42;
$gray-900: #2c3e50;
$gray-999: $black;

$screen-width-xs: 0;
$screen-width-sm: 576px;
$screen-width-md: 768px;
$screen-width-lg: 992px;
$screen-width-xl: 1200px;

$text-xl: 44px;
$text-lg: 36px;
$text-md: 26px;
$text-sm: 19px;
$text-xs: 14px;

// Required mixin
$enable-hover-media-query: false;

// https://gist.githubusercontent.com/epatr/5c94c4d46978a0b1faad134ec7eb2aeb/raw/ce06f57ec86a1f75933b900fb2a360f79945262d/_reboot-variables.scss
@mixin hover {
  &:hover {
    @content;
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

// Variables
$white: #fff;
$black: #000;
$blue: #0275d8;
$gray-dark: #292b2c;
$gray-light: #636c72;
$brand-primary: $red;
$text-muted: $gray-light;
$font-family-serif: "Quarto Bold", quatro, serif;
$font-family-sans-serif: sofia-pro, sans-serif;
$font-weight-normal: normal;
$font-weight-bold: bold;

// Reboot settings
$spacer: 1rem !default;
$font-family-base: $font-family-sans-serif;
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-weight-base: $font-weight-normal;
$font-weight-bolder: bolder !default;

$headings-margin-bottom: $spacer / 2 !default;

$paragraph-margin-bottom: 1rem !default;

$line-height-base: 1.5;
$body-color: $gray-dark;
$body-bg: $white;
$dt-font-weight: $font-weight-bold;
$link-color: $brand-primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
$table-bg: transparent;
$table-cell-padding: .75rem;
$cursor-disabled: not-allowed;
$table-caption-color: $text-muted !default;

$label-margin-bottom: .5rem !default;
$enable-pointer-cursor-for-buttons: true !default;
