
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        






















































































































































































































@import "~vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";

.cookie {
  &__floating {
    background: $gray-100 !important;
    box-shadow: none !important;
    border: 1px solid $gray-200 !important;
    &--bottom-right {
      @media (max-width: $screen-width-sm) {
        top: 20px !important;
        bottom: auto !important;
        right: auto !important;
        left: 10vw !important;
        margin: 0 0 !important;
        width: 80vw !important;
      }
    }

    &__content {
      overflow: hidden !important;
    }

    &__buttons {
      &__button {
        background-color: $white !important;
        border-bottom: 1px solid $gray-100 !important;
        border-top: 1px solid $gray-100 !important;

        &--accept {
          color: $green !important;

          &:hover {
            background: $green !important;
            color: $white !important;
          }
        }

        &--decline {
          color: $red !important;
          text-decoration: none !important;

          &:hover {
            background: $white !important;
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
