
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        




















































































.nav {
  width: 100%;
  text-align: left;
  background-color: $accent-color;
  color: $white;
  font-size: $text-sm;

  nav {
    max-width: $screen-width-lg;
    margin: auto;
    padding: $text-sm $text-xs;

    @media (max-width: $screen-width-md) {
      padding: 0.5em $text-md;
      justify-content: space-between;
      display: block;
    }

    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $gray-400;
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;

    @media (max-width: $screen-width-sm) {
      flex-direction: column;
      text-align: right;
    }

    justify-content: space-between;
    margin: 0;
  }

  &-toggler {
    position: relative;
    top: -0.5 * $text-lg;
    display: none !important;

    @media (max-width: $screen-width-sm) {
      z-index: 0;
      display: block !important;
      font-size: $text-lg;
    }
  }

  &-menu {
    display: block;

    @media (max-width: $screen-width-sm) {
      display: none;
    }
  }

  .hidden {
    @media (max-width: $screen-width-sm) {
      display: none !important;
    }
  }

  &-logo {
    padding: 5px 0;
    margin-top: -1px;
    width: 200px;
    z-index: 999;
    @media (max-width: $screen-width-sm) {
      height: 1.5em;
      width: 280px;
    }
    @media (max-width: 370px) {
      padding: 12px 0;
      width: 200px;
    }
  }

  &-button {
    @include button;
    border: 1px solid transparent;
    padding: 0;
    display: flex;

    a {
      padding: 10px $text-sm;
    }

    @media (max-width: $screen-width-sm) {
      font-size: $text-md;
      margin-bottom: 0.5em;
    }

    &:hover,
    &:hover a,
    &:focus {
      color: $gray-400;
    }

    &.active {
      color: $white;
      font-weight: 900;
    }

    &-cta {
      a {
        border: 1px solid $white;
      }

      &:hover,
      &:hover a,
      &:focus {
        color: $accent-color;
        background-color: white;
        text-decoration: none;
        outline: 0;
      }
    }
  }
}
