@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.footer {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #fff;
  background-color: #3b3d42;
  text-align: left;
  padding: 36px 19px;
  padding-bottom: 108px;
}
.footer footer {
    max-width: 992px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
@media (max-width: 768px) {
.footer footer {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.footer footer .site-section {
          padding-bottom: 26px;
          padding-right: 36px;
}
}
@media (max-width: 576px) {
.footer footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
}
}
.footer * {
    font-size: 14px;
}
.footer a {
    color: #888894;
    text-decoration: none;
}
.footer a:hover {
      text-decoration: none;
      color: #fff;
}
.footer .social-follow {
    padding-top: 20px;
    padding-bottom: 10px;
}
.footer .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.footer .social-icons svg {
      width: 30px;
}
.footer .social-icons a {
      color: #888894;
      text-decoration: none;
}
.footer .social-icons a:hover {
        text-decoration: none;
        color: #fff;
}
.footer ul {
    margin-bottom: 0;
    padding: 0;
}
.footer li {
    list-style: none;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.not-found[data-v-f30a317e] {
  width: 100%;
  height: 100%;
  background-color: #edeae5;
  padding: 30px 0;
}
.not-found-container[data-v-f30a317e] {
    max-width: 768px;
    min-height: 576px;
    margin: 0 auto;
    position: relative;
}
.not-found-image[data-v-f30a317e] {
    position: absolute;
    width: 80%;
}
@media (max-width: 576px) {
.not-found-image[data-v-f30a317e] {
        position: relative;
        width: 100vw;
}
}
.not-found-content[data-v-f30a317e] {
    position: absolute;
    width: 65%;
    right: 0;
    padding: 10px;
    margin-top: 30%;
}
@media (max-width: 576px) {
.not-found-content[data-v-f30a317e] {
        margin: 0;
        position: relative;
        width: 100vw;
}
}
.not-found h2[data-v-f30a317e] {
    font-family: sans-serif;
}
.not-found a[data-v-f30a317e] {
    font-weight: bold;
    color: #000;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.nav {
  width: 100%;
  text-align: left;
  background-color: #f3594c;
  color: #fff;
  font-size: 19px;
}
.nav nav {
    max-width: 992px;
    margin: auto;
    padding: 19px 14px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
@media (max-width: 768px) {
.nav nav {
        padding: 0.5em 26px;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        display: block;
}
}
.nav a {
    color: #fff;
    text-decoration: none;
}
.nav a:hover {
      color: #c8c9ba;
      text-decoration: none;
}
.nav ul {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0;
}
@media (max-width: 576px) {
.nav ul {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        text-align: right;
}
}
.nav-toggler {
    position: relative;
    top: -18px;
    display: none !important;
}
@media (max-width: 576px) {
.nav-toggler {
        z-index: 0;
        display: block !important;
        font-size: 36px;
}
}
.nav-menu {
    display: block;
}
@media (max-width: 576px) {
.nav-menu {
        display: none;
}
}
@media (max-width: 576px) {
.nav .hidden {
      display: none !important;
}
}
.nav-logo {
    padding: 5px 0;
    margin-top: -1px;
    width: 200px;
    z-index: 999;
}
@media (max-width: 576px) {
.nav-logo {
        height: 1.5em;
        width: 280px;
}
}
@media (max-width: 370px) {
.nav-logo {
        padding: 12px 0;
        width: 200px;
}
}
.nav-button {
    display: inline-block;
    font-size: 19px;
    line-height: 1.2;
    font-weight: inherit;
    color: #000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0;
    padding: 10px 44px 12px 44px;
    border: 1px solid transparent;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.nav-button:hover {
      color: #000;
      text-decoration: none;
}
.nav-button:focus, .nav-button.focus {
      outline: 0;
      color: #f3594c;
      background-color: #fff;
      border-color: #fff;
}
.nav-button a {
      padding: 10px 19px;
}
@media (max-width: 576px) {
.nav-button {
        font-size: 26px;
        margin-bottom: 0.5em;
}
}
.nav-button:hover,
    .nav-button:hover a, .nav-button:focus {
      color: #c8c9ba;
}
.nav-button.active {
      color: #fff;
      font-weight: 900;
}
.nav-button-cta a {
      border: 1px solid #fff;
}
.nav-button-cta:hover,
    .nav-button-cta:hover a, .nav-button-cta:focus {
      color: #f3594c;
      background-color: white;
      text-decoration: none;
      outline: 0;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.cookie__floating {
  background: #edeae5 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: 1px solid #d8d6cd !important;
}
@media (max-width: 576px) {
.cookie__floating--bottom-right {
      top: 20px !important;
      bottom: auto !important;
      right: auto !important;
      left: 10vw !important;
      margin: 0 0 !important;
      width: 80vw !important;
}
}
.cookie__floating__content {
    overflow: hidden !important;
}
.cookie__floating__buttons__button {
    background-color: #fff !important;
    border-bottom: 1px solid #edeae5 !important;
    border-top: 1px solid #edeae5 !important;
}
.cookie__floating__buttons__button--accept {
      color: #41b883 !important;
}
.cookie__floating__buttons__button--accept:hover {
        background: #41b883 !important;
        color: #fff !important;
}
.cookie__floating__buttons__button--decline {
      color: #f3594c !important;
      text-decoration: none !important;
}
.cookie__floating__buttons__button--decline:hover {
        background: #fff !important;
        text-decoration: underline !important;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype"); }

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype"); }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #292b2c;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #f3594c;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #e3200f;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

html,
body {
  font-family: sofia-pro, sans-serif;
  color: #3b3d42;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.background-light {
  background-color: #edeae5; }

.color-gray-400 {
  color: #c8c9ba; }

h1,
h2,
h3,
h4 {
  line-height: 1;
  color: #f3594c; }

h1 {
  font-size: 44px; }

h2 {
  font-size: 26px; }

.separator {
  height: 8px;
  width: 160px;
  background-color: #f3594c;
  content: "";
  margin: auto;
  margin-bottom: 44px; }

.body {
  max-width: 100%;
  margin: auto; }
  @media (max-width: 576px) {
    .body {
      width: 100vw; } }

.head {
  text-align: center;
  margin: 40px 20%; }

.main {
  max-width: 992px;
  margin: auto; }
  @media (max-width: 576px) {
    .main {
      width: 100vw; } }

.cta {
  text-align: center;
  padding: 36px 0;
  color: #fff; }
  .cta-button {
    display: inline-block;
    font-size: 19px;
    line-height: 1.2;
    font-weight: inherit;
    color: #000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0;
    padding: 10px 44px 12px 44px;
    background-color: #f3594c;
    color: #fff;
    border: 1px solid #f3594c; }
    .cta-button:hover {
      color: #000;
      text-decoration: none; }
    .cta-button:focus, .cta-button.focus {
      outline: 0;
      color: #f3594c;
      background-color: #fff;
      border-color: #fff; }
    .cta-button:hover, .cta-button:focus {
      background-color: #fff;
      color: #f3594c;
      border: 1px solid #fff;
      text-decoration: none; }
  .cta .icon {
    margin-left: -6px;
    margin-bottom: -18px;
    margin-top: -18px;
    display: inline-block;
    width: 36px; }

.mentor-details p {
  color: #888894; }

.mentor-details .quote {
  padding: 19px 0;
  color: #aca79e;
  font-family: "Quarto Bold", quatro, serif;
  font-weight: 300;
  font-size: 26px; }

.mentor-details-image {
  position: relative;
  width: 100%;
  background-color: #d8d6cd; }
  .mentor-details-image img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top center;
       object-position: top center;
    width: 100%;
    height: 300px; }

.mentor-details-body {
  text-align: center; }
  .mentor-details-body h1 {
    color: #f3594c; }
  .mentor-details-body h2 {
    color: #f3594c; }

@media (max-width: 576px) {
  .mentor-details .container {
    padding: 0 36px; } }

.mentor-details-name {
  padding: 20px 0; }
  .mentor-details-name h2 {
    font-weight: 400;
    font-size: 17px; }

.mentor-details-square {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 576px) {
    .mentor-details-square {
      width: 30vw; } }

.mentor-details-icon {
  color: #f3594c;
  width: 60px; }

.mentor-details hr {
  border: 5px solid #f3594c;
  width: 15%;
  margin-top: 10px; }

.mentor-details-button {
  background-color: #f3594c;
  margin-top: 20px;
  padding: 0.5em;
  text-align: center; }
  .mentor-details-button a {
    color: white;
    text-decoration: none; }
    .mentor-details-button a:hover {
      text-decoration: underline; }

.mentor-details-squares {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: auto;
  width: 992px; }
  @media (max-width: 576px) {
    .mentor-details-squares {
      width: 100vw;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }

.mentor-details-link-arrow {
  margin-left: auto;
  margin-right: 0px; }

.mentor-details .hide {
  display: none; }

.mentor-details .booking {
  margin-top: 40px; }

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 19px !important; }

.multiselect__input,
.multiselect__single,
.multiselect__tags,
.multiselect__tag {
  border-radius: 0 !important; }

.multiselect {
  color: gray !important; }
  @media (max-width: 576px) {
    .multiselect {
      width: 94vw !important; } }
  .multiselect__tags {
    font-size: 19px !important;
    min-height: 50px !important; }
  .multiselect__select {
    line-height: 26px; }
  .multiselect__placeholder {
    padding-left: 4px !important; }
  .multiselect__single {
    color: #2c3e50 !important;
    background: none !important;
    padding-top: 2px !important; }
  .multiselect__tag {
    color: #2c3e50 !important;
    background: none !important; }
    .multiselect__tag-icon:after {
      color: #2c3e50 !important;
      font-size: 14px; }
    .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
      background: #888894 !important; }
    .multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
      color: #fff !important; }
  .multiselect__option:after {
    font-size: 14px; }
  .multiselect__option--highlight {
    background-color: #d8d6cd !important; }
    .multiselect__option--highlight:after {
      background: #d8d6cd !important;
      color: #fff !important; }

.multiselect__option--selected {
  background: #fff !important;
  color: #888894 !important; }

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #f3594c !important;
  color: #fff !important; }

.modal {
  /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */ }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease; }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle; }
  .modal-container {
    width: 600px;
    height: 600px;
    margin: 0px auto;
    padding: 20px 20px;
    background-color: #fff;
    border-radius: 2px;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .modal-iframe {
    width: 100%;
    height: 100%;
    border: none; }
  .modal-close {
    float: right; }
    .modal-close-button {
      display: inline-block;
      font-size: 19px;
      line-height: 1.2;
      font-weight: inherit;
      color: #000;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background-color: transparent;
      border: 1px solid #000;
      border-radius: 0;
      padding: 10px 44px 12px 44px;
      background-color: #f3594c;
      color: #fff;
      border: 1px solid #f3594c;
      line-height: 1;
      padding: 0px 6px 4px 6px;
      border-radius: 4px;
      background-clip: padding-box;
      /* stops bg color from leaking outside the border: */ }
      .modal-close-button:hover {
        color: #000;
        text-decoration: none; }
      .modal-close-button:focus, .modal-close-button.focus {
        outline: 0;
        color: #f3594c;
        background-color: #fff;
        border-color: #fff; }
      .modal-close-button:hover, .modal-close-button:focus {
        background-color: #c8c9ba;
        color: #fff;
        border: 1px solid #c8c9ba;
        text-decoration: none; }
  .modal-enter {
    opacity: 0; }
  .modal-leave-active {
    opacity: 0; }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }

.whats-app-body p {
  margin: 0; }

.whats-app-body a {
  color: #f3594c;
  font-weight: bold; }

.tab-content-video-content {
  position: relative; }
  .tab-content-video-content > :first-child {
    width: 100%; }
  .tab-content-video-content > img {
    height: auto; }
  .tab-content-video-content:before {
    content: "";
    display: block;
    padding-bottom: calc(100% / 1.77778); }
  .tab-content-video-content > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }

.tab-content-video iframe {
  width: 100%;
  height: 100%; }

.tab-content-profile .tab-competence h2 {
  font-size: 19px;
  font-weight: bold;
  padding: 14px;
  margin: 0 0 19px 26px;
  line-height: 1.5;
  color: #2c3e50;
  border-left: 8px solid #f3594c; }

.tab-content-profile .tab-competence ul {
  padding: 0; }

.tab-content-profile .tab-competence li {
  list-style-type: none;
  font-weight: bold;
  color: #2c3e50;
  line-height: 2; }
  .tab-content-profile .tab-competence li:before {
    content: "";
    display: inline-block;
    background-image: url("/img/icons/arrow_bullit.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    margin-right: 1em; }

.tab-content-education .tab-competence-content h2, .tab-content-education .tab-competence-content h3, .tab-content-education .tab-competence-content p {
  margin-left: 85px; }
  @media (max-width: 576px) {
    .tab-content-education .tab-competence-content h2, .tab-content-education .tab-competence-content h3, .tab-content-education .tab-competence-content p {
      margin-left: 65px; } }

.tab-content-education .tab-competence-content h2 {
  color: #414042;
  word-break: break-all; }

.tab-content-education .tab-competence-content h3 {
  color: #414042;
  font-size: 14px; }

.tab-content-education .tab-competence-content p {
  margin-bottom: 3em; }
  .tab-content-education .tab-competence-content p:last-of-type {
    margin-bottom: 0; }
  @media (max-width: 576px) {
    .tab-content-education .tab-competence-content p {
      margin-left: 0; } }

.tab-content-education .tab-competence-content img {
  float: left;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */ }
  @media (max-width: 576px) {
    .tab-content-education .tab-competence-content img {
      width: 60px;
      height: 60px; } }

.tab-content-references .tab-competence-content h2,
.tab-content-references .tab-competence-content h3,
.tab-content-references .tab-competence-content p {
  margin-left: 120px; }
  @media (max-width: 576px) {
    .tab-content-references .tab-competence-content h2,
    .tab-content-references .tab-competence-content h3,
    .tab-content-references .tab-competence-content p {
      margin-left: 65px; } }

.tab-content-references .tab-competence-content h3 {
  color: #414042;
  font-size: 14px;
  line-height: 1.6; }
  .tab-content-references .tab-competence-content h3 a {
    color: #f3594c; }

.tab-content-references .tab-competence-content p {
  margin-bottom: 3em; }
  .tab-content-references .tab-competence-content p:last-of-type {
    margin-bottom: 0; }
  @media (max-width: 576px) {
    .tab-content-references .tab-competence-content p {
      margin-left: 0; } }

.tab-content-references .tab-competence-content img {
  margin-top: 5px;
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border: 1px solid #c8c9ba; }
  @media (max-width: 576px) {
    .tab-content-references .tab-competence-content img {
      width: 60px;
      height: 60px; } }

.tab-content-presentations .tab-competence > div > p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.tab-content-presentations .tab-competence a {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #414042;
  position: relative;
  margin-right: 10px; }
  .tab-content-presentations .tab-competence a > :first-child {
    width: 100%; }
  .tab-content-presentations .tab-competence a > img {
    height: auto; }
  .tab-content-presentations .tab-competence a:before {
    content: "";
    display: block;
    padding-bottom: calc(100% / 1.33333); }
  .tab-content-presentations .tab-competence a > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  .tab-content-presentations .tab-competence a:last-of-type {
    margin-right: 0; }

.tab-content-videos .tab-competence > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.tab-content-videos .tab-competence iframe {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  max-width: 100%;
  margin-right: 10px; }
  .tab-content-videos .tab-competence iframe:last-of-type {
    margin-right: 0; }

.content-main .content-body iframe {
  width: 100%;
  min-height: 200px; }


/*# sourceMappingURL=main.bc114149.css.map*/