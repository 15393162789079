
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        
@import 'fonts';
@import 'variables';
@import 'rfs';
@import 'reboot';

html,
body {
  font-family: $font-family-base;
  color: $gray-800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-light {
  background-color: $gray-100;
}

.color-gray-400 {
  color: $gray-400;
}

h1,
h2,
h3,
h4 {
  line-height: 1;
  color: $accent-color;
}

h1 {
  font-size: $text-xl;
}

h2 {
  font-size: $text-md;
}

.separator {
  height: 8px;
  width: 160px;
  background-color: $accent-color;
  content: "";
  margin: auto;
  margin-bottom: $text-xl;
}

.body {
  max-width: 100%;
  margin: auto;

  @media(max-width: $screen-width-sm) {
    width: 100vw;
  }
}

.head {
  text-align: center;
  margin: 40px 20%;
}

.main {
  max-width: $screen-width-lg;
  margin: auto;

  @media(max-width: $screen-width-sm) {
    width: 100vw;
  }
}

.cta {
  text-align: center;
  padding: $text-lg 0;
  color: $white;

  &-button {
    @include button;

    background-color: $accent-color;
    color: $white;
    border: 1px solid $accent-color;

    &:hover,
    &:focus {
      background-color: $white;
      color: $accent-color;
      border: 1px solid $white;
      text-decoration: none;
    }
  }

  .icon {
    margin-left: -6px;
    margin-bottom: -$text-lg/2;
    margin-top: -$text-lg/2;
    display: inline-block;
    width: $text-lg;
  }
}

.mentor-details {
  p {
    color: $gray-600;
  }

  .quote {
    padding: $text-sm 0;
    color: $gray-500;
    font-family: $font-family-serif;
    font-weight: 300;
    font-size: $text-md;
  }

  &-image {
    position: relative;
    width: 100%;
    background-color: $gray-200;

    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 300px;
    }
  }

  &-body {
    text-align: center;

    h1 {
      color: $accent-color;
    }

    h2 {
      color: $accent-color;
    }
  }

  .container {
    @media (max-width: $screen-width-sm) {
      padding: 0 $text-lg;
    }
  }

  &-name {
    padding: 20px 0;

    h2 {
      font-weight: 400;
      font-size: 17px;
    }
  }

  &-square {
    width: 20%;

    @media (max-width: $screen-width-sm) {
      width: 30vw;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-icon {
    color: $accent-color;
    width: 60px;
  }

  hr {
    border: 5px solid $accent-color;
    width: 15%;
    margin-top: 10px;
  }

  &-button {
    background-color: $accent-color;
    margin-top: 20px;
    padding: 0.5em;
    text-align: center;

    & a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-squares {
    display: flex;
    justify-content: space-around;
    margin: auto;
    width: $screen-width-lg;

    @media (max-width: $screen-width-sm) {
      width: 100vw;
      flex-wrap: wrap;
    }
  }

  &-link-arrow {
    margin-left: auto;
    margin-right: 0px;
  }

  .hide {
    display: none;
  }

  .booking {
    margin-top: 40px;
  }
}


@import "~vue-multiselect/dist/vue-multiselect.min.css";

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: $text-sm !important;
}

.multiselect__input,
.multiselect__single,
.multiselect__tags,
.multiselect__tag {
  border-radius: 0 !important;
}

.multiselect {
  color: gray !important;

  @media (max-width: $screen-width-sm) {
    width: 94vw !important;
  }

  &__tags {
    font-size: $text-sm !important;
    min-height: 50px !important;
  }

  &__select {
    line-height: $text-md;
  }

  &__placeholder {
    padding-left: 4px !important;
  }

  &__single {
    color: $gray-900 !important;
    background: none !important;
    padding-top: 2px !important;
  }

  &__tag {
    color: $gray-900 !important;
    background: none !important;

    &-icon:after {
      color: $gray-900 !important;
      font-size: $text-xs;
    }

    &-icon:focus,
    &-icon:hover {
      background: $gray-600 !important;
    }

    &-icon:focus:after,
    &-icon:hover:after {
      color: $white !important;
    }
  }

  &__option {
    &:after {
      font-size: $text-xs;
    }

    &--highlight {
      background-color: $gray-200 !important;

      &:after {
        background: $gray-200 !important;
        color: $white !important;
      }
    }
  }
}

.multiselect__option--selected {
  background: $white !important;
  color: $gray-600 !important;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight:after {
  background: $accent-color !important;
  color: $white !important;
}

.modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  &-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &-container {
    width: 600px;
    height: 600px;
    margin: 0px auto;
    padding: 20px 20px;
    background-color: #fff;
    @include border-radius(2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  &-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &-close {
    float: right;

    &-button {
      @include button;

      background-color: $accent-color;
      color: $white;
      border: 1px solid $accent-color;
      line-height: 1;
      padding: 0px 6px 4px 6px;
      @include border-radius(4px);

      &:hover,
      &:focus {
        background-color: $gray-400;
        color: $white;
        border: 1px solid $gray-400;
        text-decoration: none;
      }
    }
  }

  /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter &-container,
  &-leave-active &-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.whats-app-body {
  p {
    margin: 0;
  }
  a {
    color: $red;
    font-weight: bold;
  }
}

.tab-content-video {
  &-content {
    @include aspect-ratio(16/9);
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

// mentor profielen
.tab-content-profile {
  .tab-competence {
    h2 {
      font-size: $text-sm;
      font-weight: bold;
      padding: $text-xs;
      margin: 0 0 $text-sm $text-md;
      line-height: 1.5;
      color: $gray-900;
      border-left: 8px solid $red;
    }
    ul {
      padding: 0;
    }
    li {
      list-style-type: none;
      font-weight: bold;
      color: $gray-900;
      line-height: 2;

      &:before {
        content: "";
        display: inline-block;
        background-image: url('/img/icons/arrow_bullit.svg');
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        margin-right: 1em;
      }
    }
  }
}
.tab-content-education {
  .tab-competence-content {
    h2, h3, p {
      margin-left: 85px;
      @media (max-width: $screen-width-sm) {
        margin-left: 65px;
      }
    }
    h2 {
      color: $gray-700;
      word-break: break-all;
    }
    h3 {
      color: $gray-700;
      font-size: $text-xs;
    }
    p {
      margin-bottom: 3em;
      &:last-of-type {
        margin-bottom: 0;
      }
      @media (max-width: $screen-width-sm) {
        margin-left: 0;
      }
    }
    img {
      float: left;
      width: 70px;
      height: 70px;
      @include border-radius(50%);
      @media (max-width: $screen-width-sm) {
        width: 60px;
        height: 60px;
      }
    }
  }
}
.tab-content-references {
  .tab-competence-content {
    h2,
    h3,
    p {
      margin-left: 120px;
      @media (max-width: $screen-width-sm) {
        margin-left: 65px;
      }
    }

    h3 {
      color: $gray-700;
      font-size: $text-xs;
      line-height: 1.6;
      a {
        color: $red;
      }
    }

    p {
      margin-bottom: 3em;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: $screen-width-sm) {
        margin-left: 0;
      }
    }

    img {
      margin-top: 5px;
      float: left;
      width: 100px;
      height: 100px;
      @include border-radius(50%);
      border: 1px solid $gray-400;
      @media (max-width: $screen-width-sm) {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.tab-content-presentations {
  .tab-competence {
    > div > p {
      display: flex;
    }
    a {
      flex: 1 1 100%;
      display: flex;
      justify-content: center;
      color: $gray-700;
      @include aspect-ratio(4/3);

      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.tab-content-videos {
  .tab-competence {
    >div {
      display: flex;
    }
    iframe {
      flex: 1 1 100%;
      max-width: 100%;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.content-main {
  .content-body {
    iframe {
      width: 100%;
      min-height: 200px;
    }
  }
}
