
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        


























.not-found {
  width: 100%;
  height: 100%;
  background-color: $gray-100;
  padding: 30px 0;

  &-container {
    max-width: $screen-width-md;
    min-height: $screen-width-sm;
    margin: 0 auto;
    position: relative;
  }

  &-image {
    position: absolute;
    width: 80%;
    @media (max-width: $screen-width-sm) {
      position: relative;
      width: 100vw;
    }
  }

  &-content {
    position: absolute;
    width: 65%;
    right: 0;
    padding: 10px;
    margin-top: 30%;
    @media (max-width: $screen-width-sm) {
      margin: 0;
      position: relative;
      width: 100vw;
    }
  }

  h2 {
    font-family: sans-serif;
  }

  a {
    font-weight: bold;
    color: $black;
  }
}
