
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        

































































































































































































.footer {
  width: 100%;
  justify-content: space-between;
  color: $white;
  background-color: $gray-800;
  text-align: left;
  padding: $text-lg $text-sm;
  padding-bottom: $text-lg * 3;

  & footer {
    max-width: $screen-width-lg;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $screen-width-md) {
      flex-wrap: wrap;

      .site-section {
        padding-bottom: $text-md;
        padding-right: $text-lg;
      }
    }

    @media (max-width: $screen-width-sm) {
      flex-direction: column;
    }
  }

  & * {
    font-size: $text-xs;
  }

  & a {
    color: $gray-600;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }

  .social-follow {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .social-icons {
    display: flex;

    & svg {
      width: 30px;
    }

    & a {
      color: $gray-600;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: $white;
      }
    }
  }
  ul {
    margin-bottom: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
}
